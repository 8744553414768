@import '../../node_modules/@fortawesome/fontawesome-pro/scss/variables';
@import 'mc-v4-shims-required';
@import 'v4-shim-before-content-var-name';
@import 'v4-shim-font-mapping';

$brandFontIconListStr: "";
$proFontIconListStr: "";
@each $iconName in $mcLegacyFaIcons {
    $fontType: map-get($v4ShimfontMapping, $iconName);
    @if($fontType == "Brands"){
        @if($brandFontIconListStr == ""){
            $brandFontIconListStr: ".#{$fa-css-prefix}.#{$fa-css-prefix}-#{$iconName}";
        }
        @else {
            $brandFontIconListStr: "#{$brandFontIconListStr}, .#{$fa-css-prefix}.#{$fa-css-prefix}-#{$iconName}";
        }
    }
    @else if($fontType == "Pro"){
        @if($proFontIconListStr == ""){
            $proFontIconListStr: ".#{$fa-css-prefix}.#{$fa-css-prefix}-#{$iconName}";
        }
        @else {
            $proFontIconListStr: "#{$proFontIconListStr}, .#{$fa-css-prefix}.#{$fa-css-prefix}-#{$iconName}";
        }
    }
    $beforeContentVar: map-get($v4ShimBeforeContentVarMapping, $iconName);
    @if ($beforeContentVar != null){
        .#{$fa-css-prefix}.#{$fa-css-prefix}-#{$iconName}:before { content: fa-content($beforeContentVar); }
    }
}
@if($brandFontIconListStr != ""){
    #{$brandFontIconListStr} {
        font-family: 'Font Awesome 5 Brands';
        font-weight: 400;
    }
}
@if($proFontIconListStr != ""){
    #{$proFontIconListStr} {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
    }
}
.pull-left  { float: left; }
.pull-right { float: right; }